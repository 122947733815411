import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { OdooServicesComponent } from "../odoo-services/odoo-services.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../../contact-us/contact-us.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CanonicalService } from '../../../services/canonical.service';

@Component({
  selector: 'app-customisation',
  standalone: true,
  imports: [OdooServicesComponent, CommonModule],
  templateUrl: './customisation.component.html',
  styleUrl: './customisation.component.scss'
})
export class CustomisationComponent {

  // Dynamic accordion data
  accordionData = [
    {
      title: "What is Odoo customisation, and why do I need it?",
      content: `Odoo customisation involves modifying and adapting the Odoo ERP modules to suit your specific business needs. This can range from altering existing features to adding entirely new functionalities. customisation is essential because it allows your business to operate more efficiently by ensuring the ERP system aligns perfectly with your processes, workflows, and industry-specific requirements.`
    },
    {
      title: "How does AttributeX approach Odoo customisation?",
      content: `At AttributeX, we start by understanding your business needs and objectives. Our experts then assess your current processes and identify areas where Odoo can be tailored to enhance efficiency. We design, develop, and implement customized solutions that seamlessly integrate with your existing systems. Our approach is collaborative, ensuring that the final product is perfectly aligned with your expectations.`
    },
    {
      title: "What industries does AttributeX serve with Odoo customisation?",
      content: `AttributeX provides Odoo customisation services across a wide range of industries, including but not limited to manufacturing, retail, healthcare, finance, and logistics. We tailor our solutions to meet the unique challenges and requirements of each industry, ensuring that your Odoo system supports your specific business goals.`
    },
    {
      title: "How long does it take to customize Odoo for my business?",
      content: `The timeline for Odoo customisation depends on the complexity and scope of your project. Simple customisations can be completed in a few weeks, while more extensive modifications may take several months. During our initial consultation, we will provide you with a detailed timeline based on your specific requirements.`
    },
    {
      title: "What support does AttributeX offer after the customisation is complete?",
      content: `AttributeX offers comprehensive post-customisation support, including ongoing maintenance, troubleshooting, and updates. Our goal is to ensure that your Odoo system continues to perform optimally as your business evolves. We also offer training sessions to help your team get the most out of the customized Odoo features.`
    },
    {
      title: "Can I integrate other software with my customized Odoo system?",
      content: `Yes, AttributeX can help you integrate Odoo with other software systems you may be using, such as CRM tools, e-commerce platforms, or industry-specific applications. Our team ensures that these integrations are seamless, allowing for smooth data flow and enhanced operational efficiency.`
    },
    {
      title: "How much does Odoo customisation cost with AttributeX?",
      content: `The cost of Odoo customisation varies depending on the complexity and scale of the project. We offer competitive pricing tailored to your specific needs. During our initial consultation, we’ll provide you with a detailed quote based on the customisations required for your business.`
    },
    {
      title: "Is Odoo customisation suitable for small businesses?",
      content: `Absolutely! Odoo’s modular structure makes it an excellent choice for businesses of all sizes. Whether you’re a small business looking to streamline operations or a large enterprise needing advanced features, AttributeX can customize Odoo to suit your specific needs and budget.`
    },
    {
      title: "How do I get started with Odoo customisation from AttributeX?",
      content: `Getting started is easy! Simply contact us through our website or give us a call. We’ll schedule a consultation to discuss your business needs, provide a tailored solution, and outline the next steps to begin the customisation process.`
    }
  ];

  odooPartner = [
    {
      icon: "assets/images/odoo/development.svg",
      service_name: "Expertise in Odoo customisation",
      description: "Our team of seasoned professionals has deep expertise in customizing Odoo modules to fit your specific requirements. Whether you need modifications in accounting, CRM, inventory, or any other module, we ensure a seamless integration that aligns with your business processes."
    },
    {
      icon: "assets/images/odoo/studio.svg",
      service_name: "Tailored Solutions for Every Industry",
      description: "We understand that every industry has its own set of challenges. That's why we offer industry-specific Odoo solutions that address your unique pain points and help you achieve your business objectives."
    },
    {
      icon: "assets/images/odoo/automation.svg",
      service_name: "Seamless Integration & Automation",
      description: "AttributeX ensures that your customized Odoo system integrates seamlessly with your existing operations, enabling automation and real-time reporting that keeps you ahead of the competition."
    },
    {
      icon: "assets/images/odoo/consultants.svg",
      service_name: "Dedicated Support",
      description: "Our commitment to your success doesn't end with implementation. We provide ongoing support and maintenance to ensure your Odoo system continues to deliver optimal performance as your business grows."
    }
  ];
  currentUrl: any;

  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;


  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private _changeDetectorRef: ChangeDetectorRef,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        this.canonicalService.setCanonicalUrl(this.currentUrl);
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();
  }

  updateMetaTags() {
    const routeWithoutFirstCharacter = this.router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (currentRoute == 'customisation') {
      this.titleService.setTitle('AttributeX: Your Trusted Odoo customisation Partner in the USA');
      this.meta.updateTag({ name: 'description', content: "As a certified Odoo partner in the USA, we specialize in providing top-notch Odoo customisation services. Contact us today to discuss how AttributeX can tailor Odoo to meet your business needs." });
      this.meta.updateTag({ name: 'title', content: 'AttributeX: Your Trusted Odoo customisation Partner in the USA' });
      this.meta.updateTag({ name: 'keywords', content: 'odoo customisation' });
    }

    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const videoElement = this.videoPlayer.nativeElement;
    const rect = videoElement.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

    if (!isVisible) {
      videoElement.pause();  // Corrected: Use the pause() method instead of setting paused property
    }
  }

  openModal() {
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
  }
}
