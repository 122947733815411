import { Routes } from '@angular/router';
import { WorkivaComponent } from './components/workiva/workiva.component';
import { HomeComponent } from './components/home/home.component';
import { AnaplanComponent } from './components/anaplan/anaplan.component';
import { BlogComponent } from './components/blog/blog.component';
import { ServiceComponent } from './components/service/service.component';
import { TechnologyComponent } from './components/service/technology/technology.component';
import { StaffingComponent } from './components/service/staffing/staffing.component';
import { ConsultingComponent } from './components/service/consulting/consulting.component';
import { CloudComponent } from './components/consulting/cloud/cloud.component';
import { CloudMigrationComponent } from './components/consulting/cloud-migration/cloud-migration.component';
import { OdooComponent } from './components/odoo/odoo.component';
import { ImplementationComponent } from './components/odoo/implementation/implementation.component';
import { IntegrationComponent } from './components/odoo/integration/integration.component';
import { CustomisationComponent } from './components/odoo/customisation/customisation.component';


export const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'managed-services',
        component: ServiceComponent,
    },
    {
        path: 'technology',
        component: TechnologyComponent,
        // data: { hideParent: false },
        // children: [
        //     {
        //         path: 'anaplan',
        //         component: AnaplanComponent,
        //         data: { hideParent: true }
        //     },
        //     {
        //         path: 'workiva',
        //         component: WorkivaComponent,
        //         data: { hideParent: true }
        //     },
        // ]
    },
    {
        path: 'anaplan',
        component: AnaplanComponent,
        data: { hideParent: true }
    },
    {
        path: 'workiva',
        component: WorkivaComponent,
        data: { hideParent: true }
    },
    {
        path: 'odoo',
        // component: OdooComponent,
        data: { hideParent: false },
        children: [
            {
                path: 'odoo-services',
                component: OdooComponent,  // Change if different component
                data: { hideParent: true }
            },
            {
                path: 'customisation',
                component: CustomisationComponent,  // Change if different component
                data: { hideParent: true }
            },
            // {
            //     path: 'implementation',
            //     component: ImplementationComponent,  // Change if different component
            //     data: { hideParent: true }
            // },
            // {
            //     path: 'integration',
            //     component: IntegrationComponent,  // Change if different component
            //     data: { hideParent: true }
            // }
        ]
    },
    {
        path: 'staffing',
        component: StaffingComponent,
    },
    {
        path: 'consulting',
        component: ConsultingComponent,
        data: { hideParent: false },
        children: [
            {
                path: 'cloud',
                component: CloudComponent,
                data: { hideParent: true }
            },
            {
                path: 'cloud-migration',
                component: CloudMigrationComponent,
                data: { hideParent: true }
            }
        ]
    },
    {
        path: 'blogs/:id',
        component: BlogComponent,
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }
];