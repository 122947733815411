<video #videoPlayer width="100%" controls autoplay>
    <source src="https://download.odoocdn.com/videos/odoo_com/video_homepage.webm" type="video/webm">
</video>

<div class="text-center second_section">
    <h1 class="heading">AttributeX: Your Trusted Odoo Customisation Partner in the USA</h1>
    <div class="description description_2">At AttributeX, we are more than just an Odoo partner; we are your go-to
        solution for
        customized ERP systems tailored to meet your unique business needs. As a certified Odoo partner in the USA, we
        specialize in providing top-notch Odoo customisation services that empower businesses to optimize their
        operations, enhance productivity, and drive growth.
    </div>
    <!-- <button class="custom_btn" (click)="openModal()">Book a Free ERP Consultation</button> -->
</div>

<app-odoo-services [odooPartner]="odooPartner"
    [heading]="'Why Choose AttributeX for Odoo Customisation?'"></app-odoo-services>

<div class="text-center second_section">
    <div class="heading">Transform Your Business with AttributeX and Odoo</div>
    <div class="description ">Join the growing number of businesses in the USA that have transformed their operations
        with customized Odoo solutions from AttributeX. Let us help you harness the full potential of Odoo to streamline
        your processes, reduce costs, and achieve greater efficiency.
    </div>
    <div class="description sub_description">Ready to customize your Odoo system? Contact us today and let's discuss how
        AttributeX can
        tailor Odoo to meet your business needs.</div>
    <div class="mt-4 pt-3">
        <button class="custom_btn" (click)="openModal()">Get in Touch</button>
    </div>
</div>

<hr class="mt-0 mb-5">


<div class="fourth-section seventh-section">
    <div class="container">
        <div class="row m-0">
            <div class="col-md-12">
                <div class="content_section">
                    <div class="highlight_text">Frequently asked Questions (FAQs)</div>
                </div>
            </div>

            <div class="col-md-12 pb-5">
                <div class="qtn_container">
                    <!-- <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Are you seeking a solution to streamline the end-to-end reporting process, from data
                                    collection
                                    to
                                    distribution?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Workiva’s automation and data consolidation capabilities can significantly reduce
                                    the time spent on manual data entry and minimize the risk of errors in your reports.
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Are you concerned about data security and compliance with ever-changing regulations?
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Quisque sapien augue, ornare id leo a, tristique elementum justo. Praesent non nulla
                                    sagittis, sollicitudin justo id, varius erat. Nunc sed pharetra nisl. Cras et
                                    suscipit
                                    felis, in lacinia sapien. Integer venenatis sagittis massa, eu eleifend nibh
                                    venenatis
                                    in. Pellentesque a aliquet urna. Curabitur tortor metus, ultrices sed mi at,
                                    sagittis
                                    imperdiet turpis. Suspendisse nec luctus nunc. Fusce in arcu quis lacus mollis
                                    ultrices.
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Is real-time collaboration among your team members and stakeholders a challenge?
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Praesent nec ipsum scelerisque dui condimentum pellentesque eu at lectus. Vivamus
                                    purus
                                    purus, bibendum in vestibulum ac, pharetra sit amet sapien. Nunc luctus, orci vel
                                    luctus
                                    cursus, nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi. In auctor mi
                                    ac
                                    ante tincidunt tincidunt.
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="accordion" id="accordionExample">
                        <div *ngFor="let question of accordionData; let i = index" class="accordion-item">
                            <h2 class="accordion-header" [id]="'heading' + i">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse' + i" aria-expanded="true"
                                    [attr.aria-controls]="'collapse' + i">
                                    {{ question.title }}
                                </button>
                            </h2>
                            <div [id]="'collapse' + i" class="accordion-collapse collapse" [class.show]="i === 0"
                                [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    {{ question.content }}
                                </div>
                            </div>
                            <hr *ngIf="i !== accordionData.length - 1"> <!-- Add hr except for last item -->
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>