<div class="footer-section">
    <div class="h-100">
        <div class="row m-0 h-100">
            <div class="col-lg-3">
                <div class="mb-4">
                    <img routerLink="/" class="pointer" src="assets/images/attribute_X_final.webp" height="50px"
                        alt="Attributex Logo">
                </div>

                <div class="expert_section">
                    <div class="expert_text my-2">
                        Expert IT Consulting Across Industries | Empowering Businesses with Tailored Technology
                        Solutions | Empowering Growth with Tailored Odoo Solutions
                    </div>

                    <div>
                        Address: 11040 Bollinger Canyon Rd, E455, San Ramon, California 94582, US
                    </div>
                    <div class="mt-2">
                        Contact: +1 (858)-304-8004
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="quick_links_section">
                    <div class="footer_heading pointer" routerLink="/odoo/odoo-services">Odoo</div>
                    <ul>
                        <li routerLink="/odoo/odoo-services">Odoo Consulting</li>
                        <li routerLink="/odoo/customisation">Odoo Customisation</li>
                        <li routerLink="/odoo/odoo-services">Odoo Implementation</li>
                        <li routerLink="/odoo/odoo-services">Support</li>
                        <li routerLink="/odoo/odoo-services">Odoo Migration</li>
                        <li routerLink="/odoo/odoo-services">Odoo Integration</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="quick_links_section">
                    <div class="footer_heading">Services</div>
                    <ul>
                        <li routerLink="/managed-services">Managed Services</li>
                        <li routerLink="/staffing">Staffing</li>
                        <li routerLink="/consulting">Consulting</li>
                        <li routerLink="/consulting/cloud">Cloud</li>
                        <li routerLink="/consulting/cloud-migration">Cloud Migration</li>
                        <li routerLink="/technology">Technology</li>
                        <li routerLink="/anaplan">Anaplan</li>
                        <li routerLink="/workiva">Workiva</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="footer_heading get_in_touch">Get in Touch</div>

                <div class="d-flex align-items-center social_icons">
                    <a href="https://www.linkedin.com/company/attributex/" target="_blank"><img
                            src="assets/images/linked_in.svg" width="35px" alt="LinkedIn"></a>
                    <a href="https://x.com/AttributexS" target="_blank"><img src="assets/images/x_icon.svg" width="25px"
                            alt="x logo"></a>
                </div>
                <div class="footer_heading mt-4 mb-3">Stay Updated on all the latest AttributeX news</div>

                <form class="email_section" [formGroup]="subsbscribeForm" (ngSubmit)="emailSubscribe()">
                    <div>
                        <input type="text" #InputField class="form-control emailInput" formControlName="email"
                            placeholder="Enter Email Address"
                            [ngClass]="{ 'is-invalid': subsbscribeForm.get('email')?.invalid && subsbscribeForm.get('email')?.touched }">
                        <div *ngIf="subsbscribeForm.get('email').invalid && subsbscribeForm.get('email').touched">
                            <small *ngIf="subsbscribeForm.get('email').errors?.required" class="text-danger">
                                Email is required.
                            </small>
                            <small *ngIf="subsbscribeForm.get('email').errors?.pattern" class="text-danger">
                                Please enter a valid email address.
                            </small>
                        </div>
                    </div>

                    <button class="subsbcribe_btn" type="submit" name="submit">Subscribe</button>
                </form>
            </div>
            <div class="col-12">
                <div class="text-end copyright">©Copyright {{currentYear}} all rights reserved by
                    AttributeX.</div>
            </div>
        </div>
    </div>
</div>