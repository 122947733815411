<div class="why_choose_section">
    <div class="heading">{{ heading }}</div>

    <div class="row mt-5">
        <div class="col-md-6 pb-4" *ngFor="let card of odooPartner">
            <div class="bg-white p-4 h-100">
                <img [src]="card?.icon">
                <div class="services_card mt-3">
                    <div class="title mb-2">{{ card?.service_name }}</div>
                    <div class="content" [title]="card?.description">
                        {{ card?.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button class="custom_btn mt-4" (click)="openModal()" *ngIf="buttonText">{{ buttonText }}</button>
</div>