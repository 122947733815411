<div class="slider_section d-none d-lg-block">
    <ngb-carousel #carousel class="banner_section" *ngIf="(getData?.length > 0) && !loading"
        [showNavigationArrows]="getData?.length > 1" [showNavigationIndicators]="getData.length > 1">
        <ng-template ngbSlide *ngFor="let slide of getData; let i = index">
            <img [src]="slide?.banner_image" width="100%" height="100%" alt="slide">
            <div class="carousel-caption">
                <div class="text-section" [style.textAlign]="slide?.text_align" [style.color]="slide?.text_color">
                    <div class="text1" *ngIf="slide?.sub_title">{{ slide?.sub_title }}</div>
                    <div class="text2">{{ slide?.title }}</div>
                    <div class="text3"
                        *ngIf="currentRoute !== 'managed-services' && currentRoute !== 'staffing' && currentRoute !== 'consulting' && currentRoute !== 'technology'">
                        {{ slide?.description }}</div>
                </div>
            </div>
        </ng-template>
    </ngb-carousel>
    <ngx-skeleton-loader *ngIf="loading" count="1" appearance="circle" [theme]="styles" />
</div>

<!-- for mobile -->
<div class="slider_section d-lg-none">
    <ngb-carousel #carousel class="banner_section" *ngIf="(getData?.length > 0) && !loading"
        [showNavigationArrows]="getData?.length > 1" [showNavigationIndicators]="getData.length > 1">
        <ng-template ngbSlide *ngFor="let slide of getData; let i = index">
            <img [src]="slide?.mobile_banner_image" width="100%" height="100%" alt="slide">
            <div class="carousel-caption">
                <div class="text-section" [style.textAlign]="slide?.text_align" [style.color]="slide?.text_color">
                    <div class="text1" *ngIf="slide?.sub_title">{{ slide?.sub_title }}</div>
                    <div class="text2">{{ slide?.title }}</div>
                    <div class="text3"
                        *ngIf="currentRoute !== 'managed-services' && currentRoute !== 'staffing' && currentRoute !== 'consulting' && currentRoute !== 'technology'">
                        {{ slide?.description }}</div>
                </div>
            </div>
        </ng-template>
    </ngb-carousel>
    <ngx-skeleton-loader *ngIf="loading" count="1" appearance="circle" [theme]="styles" />
</div>




<!-- for mobile version -->

<!-- <div class="slider_section d-block d-md-none">
    <div id="robotcarousell" class="carousel slide h-100" data-bs-ride="carousel" *ngIf="!loading">

        <div class="carousel-inner h-100">
            <div *ngFor="let slide of getData; let i = index" class="carousel-item slides_mobile"
                [class.active]="i === 0" [style.backgroundImage]="'url(' + slide?.mobile_banner_image + ')'">
                <div class="text-section" [style.textAlign]="slide?.text_align" [style.color]="slide?.text_color">
                    <div class="text1" *ngIf="slide?.sub_title">{{ slide?.sub_title }}</div>
                    <div class="text2">{{ slide?.title }}</div>
                    <div class="text3"
                        *ngIf="currentRoute !== 'managed-services' && currentRoute !== 'staffing' && currentRoute !== 'consulting' && currentRoute !== 'technology'">
                        {{ slide?.description }}</div>
                </div>
            </div>
        </div>

        <div class="slider_arrows" *ngIf="getData?.length > 1">
            <button class="carousel-control-prev" type="button" data-bs-target="#robotcarousell" data-bs-slide="prev">
                <span class="material-symbols-outlined fs_36 text-dark">chevron_left</span>
                <span class="visually-hidden">Previous</span>
            </button>

            <div class="carousel-indicators h-100">
                <button *ngFor="let slide of getData; let i = index" type="button" data-bs-target="#robotcarousell"
                    attr.data-bs-slide-to="{{ i }}" [class.active]="i === 0" aria-current="true"
                    attr.aria-bs-label="Slide {{ i + 1 }}"></button>
            </div>

            <button class="carousel-control-next" type="button" data-bs-target="#robotcarousell" data-bs-slide="next">
                <span class="material-symbols-outlined fs_36 text-dark">chevron_right</span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <ngx-skeleton-loader *ngIf="loading" count="1" appearance="circle" [theme]="styles" />
</div> -->






<div class="container"
    *ngIf="(currentRoute === 'managed-services' || currentRoute === 'staffing' || currentRoute === 'consulting' || currentRoute === 'technology') && !loading">
    <div class="text3 description">
        {{getData[0]?.description }}</div>
</div>
<ngx-skeleton-loader
    *ngIf="(currentRoute === 'managed-services' || currentRoute === 'staffing' || currentRoute === 'consulting' || currentRoute === 'technology') && loading"
    count="1" appearance="line" [theme]="styles2" />