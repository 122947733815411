import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { Meta, Title } from '@angular/platform-browser';
import { CommonModule, Location, isPlatformBrowser } from '@angular/common';
import { SliderComponent } from '../slider/slider.component';
import { ServicesCardsComponent } from '../services-cards/services-cards.component';
import { ServicesTilesComponent } from '../services-tiles/services-tiles.component';
import { CanonicalService } from '../../services/canonical.service';
import { OdooServicesComponent } from '../odoo/odoo-services/odoo-services.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterModule, SliderComponent, CommonModule, ServicesCardsComponent, ServicesTilesComponent, OdooServicesComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  currentRoute: any;
  domain: any;

  services = {
    heading: 'Services',
    cards: [
      {
        imageUrl: 'assets/images/services_img3.webp',
        title: 'Managed Services',
        route: '/managed-services',
        description: 'Providing comprehensive managed services to ensure optimal performance and seamless operation of your cloud infrastructure and enterprise software systems, driving efficiency and stability in business operations.'
      },
      {
        imageUrl: 'assets/images/services_img2.webp',
        title: 'Technology',
        route: '/technology',
        description: 'Specializing in technology services focused on business intelligence, cloud computing, and financial management systems to empower digital transformation and elevate business processes.'
      },
      {
        imageUrl: 'assets/images/services_img1.webp',
        title: 'Consulting',
        route: '/consulting',
        description: 'Offering expert advisory services in strategic planning and process optimization, leveraging advanced data analytics, cloud solutions, and financial planning platforms to enhance decision-making and operational efficiency.'
      },
      {
        imageUrl: 'assets/images/services_img4.webp',
        title: 'Staffing',
        route: '/staffing',
        description: 'Delivering tailored staffing solutions with an emphasis on professionals proficient in cutting-edge data analysis, cloud technologies, and enterprise resource planning, meeting the dynamic needs of your projects and organizational goals.'
      }
    ]
  }


  anaplanServices = {
    heading: 'Our Anaplan Services',
    route: '/anaplan',
    description: 'Transforming enterprise planning and performance with bespoke Anaplan solutions, tailored to streamline your business processes and enhance decision-making capabilities.',
    servicesData: [
      { imageUrl: 'assets/images/implementation.webp', title: 'Implementation and Integration' },
      { imageUrl: 'assets/images/modal-building.webp', title: 'Model Building and Optimization' },
      { imageUrl: 'assets/images/financial_analysis.webp', title: 'Financial Planning and Analysis (FP&A)' },
      { imageUrl: 'assets/images/sales_performanace.webp', title: 'Sales Performance Management (SPM)' },
      { imageUrl: 'assets/images/scenario_planning.webp', title: 'Scenario Planning and Analysis' },
      { imageUrl: 'assets/images/training_support.webp', title: 'Training and Support' },
      { imageUrl: 'assets/images/custom_board.webp', title: 'Custom Dashboard and Reporting' }
    ]
  }
  currentUrl: any;

  constructor(private modalService: NgbModal,
    private titleService: Title,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private meta: Meta,
    private _location: Location,
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private canonicalService: CanonicalService

  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;
      
      this.route.url.subscribe(urlSegments => {
        // const currentUrl = window.location.origin + this.route.snapshot.url.join('/');
        this.canonicalService.setCanonicalUrl(this.currentUrl);
    });
    }

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });
  
    // Initial meta tag setup for the default or home route
    this.updateMetaTags();
  }

  updateMetaTags() {
    // Retrieve current URL
    // const currentUrl = isPlatformBrowser(this.platformId) ? window.location.href : '';
  
    // Logic to update meta tags based on the current route
    const routeWithoutFirstCharacter = this._router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();
    
    if (currentRoute == '' || currentRoute == 'home') {
      this.titleService.setTitle('Expert IT Consulting Across Industries | Empowering Businesses with Tailored Technology Solutions');
      this.meta.updateTag({ name: 'description', content: "Unlock your industry's potential with AttributeX, offering specialized IT consulting services tailored for finance, healthcare, retail, and more. Elevate your operations, data analytics, and customer engagement with our strategic insights and innovative solutions designed to drive success in your specific sector." });
      this.meta.updateTag({ name: 'title', content: 'Expert IT Consulting Across Industries | Empowering Businesses with Tailored Technology Solutions' });
      this.meta.updateTag({ name: 'keywords', content: 'IT Consulting' });
      // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });
    }
  
    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  openModal() {
    const modalRef = this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
    // You can pass data to the modal using modalRef.componentInstance
    // For example:
    // modalRef.componentInstance.data = yourData;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
