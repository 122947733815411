<div class="custom_title">Cloud Solutions and Strategy - Powering Your Digital Transformation</div>

<!-- <app-slider></app-slider> -->
 <div>
    <img src="assets/images/consulting cloud - cover image.webp" width="100%" alt="consulting cloud">
 </div>

<div class="content_section">
    <div class="title">Unlock the Full Potential of the Cloud with Our Expert Solutions</div>
    <div class="line_height_24">In an era where agility and scalability are paramount, cloud computing stands at the forefront of digital transformation. At AttributeX, we specialize in providing comprehensive cloud solutions and strategic guidance across leading cloud platforms, including AWS, Oracle Cloud Infrastructure, Microsoft Azure, and Google Cloud Platform. Our mission is to help your business harness the power of the cloud to drive innovation, efficiency, and growth.</div>
</div>

<div class="bg_blue_section">
    <div class="custom_title p-0 mb-5">Our Cloud Solutions and Strategy Services</div>
    <div class="row m-0">
        <div class="col-md-6" *ngFor="let card of data">
            <div class="d-flex gap-3 service_section_card h-100">
                <div>
                    <img [src]="card?.icon">
                </div>
                <div class="services_card">
                    <div class="heading mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="content_section">
    <div class="title mb-5">Why Choose Our Cloud Solutions?</div>
    <div class="row">
        <div class="col-md-4 pb-4" *ngFor="let card of cloudSolutionsData">
            <div class="blue_card h-100 p-4">
                <img [src]="card?.icon">
                <div class="services_card mt-3">
                    <div class="heading mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content_section blue_card">
    <div class="title mb-5">Realize the Power of the Cloud</div>
    <div class="row">
        <div class="col-md-4 pb-4" *ngFor="let card of powerOfCloud">
            <div class="bg-white p-4 h-100">
                <img [src]="card?.icon">
                <div class="services_card mt-3">
                    <div class="heading mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg-white contact_main_section">
    <!-- <div class="container-fluid"> -->
        <!-- <div class="contact_section">
            <div class="row">
                <div class="col-md-6">
                    <div class="elevate_text mb-3 mb-md-0">{{cloudContent?.heading}}</div>
                </div>
                <div class="col-md-6 pb-3" *ngIf="cloudContent?.description">
                    <div class="elevate_description mb-3 mb-md-0">
                        {{cloudContent?.description}}</div>
                </div>
                <div class="col-md-6" [ngClass]="cloudContent?.description ? 'text-start':'text-end'">
                    <button class="contact_btn me-md-5 pe-md-5" (click)="openModal()">Contact us for more
                        information</button>
                </div>
            </div>
        </div> -->
        <div class="contact_section">
            <div class="row">
                <div class="col-md-12">
                    <div class="elevate_text mb-3 mb-md-2">Ready to Elevate Your Cloud Strategy?</div>
                </div>
                <div class="col-md-12 pb-3">
                    <div class="elevate_description mb-3">Contact us today to learn how our cloud solutions and strategy services can transform your business.</div>
                </div>
                <div class="col-md-12">
                    <button class="contact_btn" (click)="openModal()">Get in Touch</button>
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>